import Vue from 'vue'
import App from './App.vue'
import echarts from 'echarts';
import router from './router.js';

// iconfont图标库
import "@/assets/css/iconfont.css";
import "@/assets/css/common.css";
import '@/assets/js/rem.js';
import VideoPlayer from 'vue-video-player/src';
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import {
  getDicts
} from '@/api/common' //  字典全局挂载s
Vue.prototype.$getDicts = getDicts;

Vue.use(VideoPlayer)

// data-view
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

// element-ui
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
Vue.use(ElementUI);

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts;
Vue.prototype.$bus = new Vue()

// 消息总线
Vue.prototype.$bus = new Vue();

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
