import Router from 'vue-router';
import Vue from 'vue';
import Layout from '@/components/Layout.vue'

Vue.use(Router)
const vueRouter = new Router({
    mode: 'history',
    base: '/',
    routes: [
        // 大屏显示
        {
            path: '/',
            component: Layout,
            redirect: '/beautifulCountryside',
            children: [
                {
                    // 美丽乡村
                    path: '/beautifulCountryside',
                    name: 'beautifulCountryside',
                    component: () => import('@/page/beautifulCountryside.vue')
                },
                {
                    // 产业振兴
                    path: '/productPromotion',
                    name: 'productPromotion',
                    component: () => import('@/page/productPromotion')
                },
                {
                    // 人才振兴
                    path: '/talentRevitalization',
                    name: 'talentRevitalization',
                    component: () => import('@/page/talentRevitalization')
                },
                {
                    // 文化振兴
                    path: '/culturalRevitalization',
                    name: 'culturalRevitalization',
                    component: () => import('@/page/culturalRevitalization')
                },
                {
                    // 生态振兴
                    path: '/ecologicalRevitalization',
                    name: 'ecologicalRevitalization',
                    component: () => import('@/page/ecologicalRevitalization')
                },
                {
                    // 组织振兴
                    path: '/organizationalRevitalization',
                    name: 'organizationalRevitalization',
                    component: () => import('@/page/organizationalRevitalization')
                }
            ]
        },
        // 独立页面



    ]
})
export default vueRouter;
