<template>
  <div id="top-header" ref="top-header">
    <!-- <dv-decoration-8 class="header-left-decoration" /> -->
    <div class="button-head">
      <router-link to="/beautifulCountryside">美丽乡村</router-link>
    </div>
    <div class="button-head">
      <router-link to="/productPromotion">产业振兴</router-link>
    </div>
    <div class="button-head" @click="toLink('talentRevitalization')">
      <router-link to="/talentRevitalization">人才振兴</router-link>
    </div>
    <!--    <dv-decoration-3 style="width:150px;height:30px;" />-->
    <div class="header-center-decoration"></div>
    <!--    <dv-decoration-3 style="width:150px;height:30px;" />-->
    <div class="button-head overturn">
      <router-link to="/culturalRevitalization">文化振兴</router-link>
    </div>
    <div class="button-head overturn">
      <router-link to="/ecologicalRevitalization">生态振兴</router-link>
    </div>
    <div class="button-head overturn">
      <router-link to="/organizationalRevitalization">组织振兴</router-link>
    </div>

    <!-- <dv-decoration-8 class="header-right-decoration" :reverse="true" /> -->

    <div class="center-title">数字乡村一张图</div>
  </div>
</template>

<script>
export default {
  name: 'TopHeader',
  mounted() {
    // 页面大小改变时触发
    window.addEventListener('resize', this.resizeScreen, false);
    // 页面大小改变时触发
    window.addEventListener('resize', this.resizeScreen, false);
    // 鼠标移动时触发
    // window.addEventListener('mousemove',this.resizeScreen, false);
    this.resizeScreen();
  },
  beforeDestroy() {
    // 页面大小改变时触发
    window.removeEventListener('resize', this.resizeScreen, false);
    // 页面大小改变时触发
    window.removeEventListener('resize', this.resizeScreen, false);
  },
  data() {
    return {
      screenHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      screenWidth: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
    }
  },
  methods: {
    resizeScreen() {
      this.screenHeight = window.innerHeight || document.documentElement.innerHeight || document.body.clientHeight;
      this.screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const dom = this.$refs['top-header'];
      dom.style.width = `${this.screenWidth}px`;
      // dom.style.height = `${this.screenHeight}px`;
    },

  }
}
</script>

<style lang="less">
#top-header {
  position: relative;
  width: 100%;
  height: 8%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-shrink: 0;
  background: linear-gradient(rgba(0, 0, 0, 40%), rgb(0 0 0 / 5%));
  z-index: 20;

  .header-center-decoration {
    position: relative;
    width: 45%;
    height: 100%;
  }

  .header-center-decoration::after {
    content: "";
    position: absolute;
    width: 140%;
    height: 100%;
    top: 0;
    left: -20%;
    z-index: -1;
    background: url('@/assets/img/头部.png') no-repeat;
    background-size: 175% 180%;
    background-position: bottom 95% center;
  }

  .header-left-decoration,
  .header-right-decoration {
    width: 5%;
    height: 60px;
  }

  .center-title {
    position: absolute;
    color: #0dff00;
    font-size: 30px;
    font-weight: bold;
    left: 50%;
    top: 15px;
    transform: translateX(-50%);
  }

  .button-head {
    text-align: center;
    width: fit-content;
    width: 8%;
    padding: 5px 0;
    cursor: pointer;
    position: relative;

    a {
      color: white;
      font-size: larger;
      font-weight: 700;
      line-height: 40px;
      text-align: center;
      text-decoration: none;
    }

    a.router-link-exact-active,
    a.router-link-active {
      color: #0dff00;
    }
  }

  .button-head::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: url('@/assets/img/标签.png') no-repeat;
    background-size: 100% 100%;
    background-position: center;
  }

  .overturn::after {
    transform: rotate(180deg);
  }
}
</style>
