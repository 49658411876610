<template>
  <div id="full-screen-container" ref="full-screen-container">
    <!--   头   -->
    <top-header />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import topHeader from '@/components/topHeader.vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Layout',
  components: {
    topHeader
  },
  mounted() {
    // 页面大小改变时触发
    window.addEventListener('resize', this.resizeScreen, false);
    // 页面大小改变时触发
    window.addEventListener('resize', this.resizeScreen, false);
    // 鼠标移动时触发
    // window.addEventListener('mousemove',this.resizeScreen, false);
    this.resizeScreen();
  },
  beforeDestroy() {
    // 页面大小改变时触发
    window.removeEventListener('resize', this.resizeScreen, false);
    // 页面大小改变时触发
    window.removeEventListener('resize', this.resizeScreen, false);
  },
  data() {
    return {
      screenHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      screenWidth: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
    }
  },
  methods: {
    resizeScreen() {
      this.screenHeight = window.innerHeight || document.documentElement.innerHeight || document.body.clientHeight;
      this.screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const dom = this.$refs['full-screen-container'];
      dom.style.width = `${this.screenWidth}px`;
      dom.style.height = `${this.screenHeight}px`;
    },

  }
}
</script>

<style lang="less" scoped>
// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.5s ease;
// }

// .fade-enter-from,
// .fade-leave-to {
//   opacity: .5;
// }
/* 菜单的淡入淡出效果 */
.fade-enter-active,
.fade-leave-active {
  /* 设置过渡效果的CSS属性名称 */
  transition-property: opacity margin;
  /* 设置过渡效果需要的时间 */
  transition-duration: .5s;
}

.fade-enter-active {
  /* 设置过渡效果何时开始 */
  transition-delay: .25s;
}

.fade-enter,
.fade-leave-active {
  /* 过渡效果属性 */
  opacity: 0;
  margin-left: 50px;
}

#full-screen-container {
  color: #fff;
  //width: 2560px;
  //height: 1440px;
  background-image: url('@/assets/bg.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  transform-origin: left top;
  z-index: 999;
}
</style>
